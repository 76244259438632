import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setCssFile, setJsFile } from "../redux/actions/commonActions";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";

const ScriptInput = (props) => {
  const dispatch = useDispatch();
  let cssFile = useSelector((state) => state.common.cssFile);
  let jsFile = useSelector((state) => state.common.jsFile);
  let addProdDetails = useSelector((state) => state.common.addProdDetails);
  const defaultCss = "/* Add custom css bellow */";
  const defaultJs = "//Add custom js bellow";
  const pathHeading = process.env.REACT_APP_MODE === "prod" ? props.heading : props.heading+"_test";
  const [uploadFile, setUploadFile] = useState(
    props.type === "js"
      ? jsFile.file.length > 0
        ? jsFile.file
        : defaultJs
      : cssFile.file.length > 0
      ? cssFile.file
      : defaultCss
  );

  const [alert, setAlert] = useState({ message: "", severity: "", show: true });
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (uploadFile.toLowerCase().includes("could not get")) {
      if (props.type === "js") {
        dispatch(setJsFile({ ...jsFile, save: false }));
      } else {
        dispatch(setCssFile({ ...cssFile, save: false }));
      }
      setDisable(true);
    }
  }, []);
  const handleCancel = () => {
    props.setOpen(false);
    let file = props.type === "js" ? jsFile : cssFile;
    setUploadFile(file);
  };
  const handleSave = () => {
    let flag = true;
    if (uploadFile === "") {
      flag = false;
      showAlertMsg("File cannot be empty!", "error");
    }
    if (flag) {
      props.setOpen(false);
      if (props.type === "js") {
        dispatch(setJsFile({ file: uploadFile, save: true }));
      } else {
        dispatch(setCssFile({ file: uploadFile, save: true }));
      }
    }
  };
  const handleChange = (event) => {
    setUploadFile(event.target.value);
  };
  // on json upload click
  const handleUpload = (e) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(e.target.files[0]);
  };

  // upload json
  function onReaderLoad(event) {
    setUploadFile(event.target.result);
  }
  const showAlertMsg = (msg, sev, time = 4000) => {
    setAlert((prev) => {
      return {
        ...prev,
        message: msg,
        severity: sev,
        show: true,
      };
    });

    setTimeout(() => {
      setAlert((prev) => {
        return {
          ...prev,
          message: "",
          severity: "",
          show: false,
        };
      });
    }, time);
  };
  return (
    <Dialog open={props.open[props.name]} maxWidth="lg">
      {alert.show ? (
        <span style={{ position: "fixed", width: "59%" }}>
          <Alert severity={alert.severity} sx={{ mb: 2 }}>
            {alert.message}
          </Alert>
        </span>
      ) : (
        <></>
      )}
      <DialogTitle>
        <Stack direction="row" spacing={1} sx={{ m: 2 }}>
          <i style={{ margin: "auto" }}>
            Upload a "{props.type}" file or fill in details manually
          </i>
          <input
            style={{ marginLeft: "auto" }}
            type="file"
            onChange={handleUpload}
            accept={`.${props.type}`}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-textarea"
          name={props.type}
          value={uploadFile}
          placeholder="Add Code Here"
          multiline
          sx={{ width: "900px" }}
          minRows={20}
          maxRows={20}
          onChange={handleChange}
          disabled={disable}
        />
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            onClick={() => {
              window.open(
                  `https://cdn.unibots.in/${pathHeading}/${props.type}/${addProdDetails["CURRENT_CLIENT"]}.${props.type}`,
                  "_blank"
              );
            }}
          >
            Open File
          </Button>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="error"
              endIcon={<CancelIcon />}
            >
              <Box sx={{ height: "22px" }}> Cancel</Box>
            </Button>
            <Button variant="contained" disabled={disable} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ScriptInput;
